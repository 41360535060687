import React from "react";
import "./Text.css";

const EvaluationTools = () => {
  return (
    <div className="page">
      <h1> Evaluation Tools page placeholder</h1>
    </div>
  );
};

export default EvaluationTools;
